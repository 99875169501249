import $ from 'jquery';

export default function initFaq() {
  const $dt = $('.faq > dt');
  $dt.on('click', function () {
    const $trigger = $(this);
    $trigger.toggleClass('is-active');
    $trigger.next().stop().slideToggle(300);
  });
}
