import $ from 'jquery';
import throttle from 'lodash/throttle';
import debounce from 'lodash/debounce';
import Marquee from '../components/Marquee';

let marquee;

const $body = $('body');
const $header = $('.header');
const $openBtn = $('.header__open');
const spEmergencyHieght = 27;
const $window = $(window);

export default function initialize(willChange = true) {
  if ($('#emergency').length) {
    const $emergency = $('#emergency');
    const $emergencyClose = $('#emergency-close');
    let spOpenBtnPos = 0;

    // emergencyを表示しているとき
    if (!sessionStorage.getItem('invisible-emergency')) {
      $emergency.css('display', 'block');

      // マーキー
      const textWidth = $('.emergency__contents__paragraph').text().length * 12;
      marquee = new Marquee(
        document.querySelector('.emergency__contents__paragraph'),
        textWidth,
      );
      marquee.start();

      $window.on(
        'resize',
        debounce(() => {
          marquee.destory();
          marquee = new Marquee(
            document.querySelector('.emergency__contents__paragraph'),
            textWidth,
          );
          marquee.start();
        }, 500),
      );
    }

    // recruit-newsを表示しているとき
    if (
      !sessionStorage.getItem('invisible-emergency') &&
      $('.recruit-news-container').length
    ) {
      $('.recruit-news-container').css({
        top: spEmergencyHieght,
      });
    }

    // header__openのポジションを指定
    if (
      !sessionStorage.getItem('invisible-emergency') ||
      $('.recruit-news-container').length
    ) {
      spOpenBtnPos += parseInt($openBtn.css('top'), 10);
      if (!sessionStorage.getItem('invisible-emergency'))
        spOpenBtnPos += spEmergencyHieght;
      if ($('.recruit-news-container').length)
        spOpenBtnPos += $('.recruit-news-container').innerHeight();
      $openBtn.css('top', spOpenBtnPos);
    }

    // emergencyを閉じる
    $emergencyClose.on('click', () => {
      $openBtn.css('top', spOpenBtnPos - spEmergencyHieght);
      sessionStorage.setItem('invisible-emergency', true);

      $emergency.slideUp(300);
      setTimeout(() => {
        marquee.finish();
      }, 300);

      if (window.matchMedia('(min-width: 769px)').matches && willChange) {
        const marginTop = $header.innerHeight() - $emergency.innerHeight();
        $body.css({
          transition: 'all 0.5s ease',
          'margin-top': marginTop,
        });
      }

      // recruit-newの調整
      if ('.recruit-news-container'.length) {
        $('.recruit-news-container').css({
          transition: 'all 0.5s ease',
          top: 0,
        });
      }
      // category-menuの調整
      if (
        $('.category-menu').length &&
        window.matchMedia('(max-width: 768px)').matches
      ) {
        $body.css({
          transition: 'all 0.5s ease',
          'margin-top': 0,
        });
      }
    });

    // ページ読み込み時にcategory-menuの調整
    if (
      $('.category-menu').length &&
      window.matchMedia('(max-width: 768px)').matches &&
      !sessionStorage.getItem('invisible-emergency')
    ) {
      const marginTop = $emergency.innerHeight();
      $body.css({
        'margin-top': marginTop,
      });
    }
  }

  // spヘッダートグルボタン
  const $headerOpen = $('.header__open');
  const $headerClose = $('.header__close');
  $headerOpen.on('click', (event) => {
    event.preventDefault();
    $header.addClass('is-active');
  });
  $headerClose.on('click', (event) => {
    event.preventDefault();
    $header.removeClass('is-active');
  });

  // スクロール時
  if (window.matchMedia('(min-width: 769px)').matches && willChange) {
    $body.css('margin-top', $header.innerHeight());
  } else {
    let flag;
    $window.on(
      'scroll',
      throttle(() => {
        $headerOpen.addClass('is-scroll');
        clearTimeout(flag);
        flag = setTimeout(() => {
          $headerOpen.removeClass('is-scroll');
        }, 200);
      }, 200),
    );
  }

  // アンカーリンクの場合ヘッダーの高さ分ずらす
  const $html = $('body, html');
  if (window.matchMedia('(min-width: 769px)').matches) {
    const { hash } = window.location;
    if (hash) {
      $html.stop().scrollTop(0);
      const headerHeight = $('.header').innerHeight();
      const hashPos = $(`${hash}`).offset().top;
      const scrollTop = hashPos - headerHeight;
      setTimeout(() => {
        $html.stop().animate({ scrollTop }, 0);
      }, 100);
    }
  }
}
