import $ from 'jquery';

export default function categoryList() {
  const $toggleButton = $('.category-list__selected');
  $toggleButton.on('click', function (e) {
    e.preventDefault();
    $(this).toggleClass('is-active');
    $(this).next().stop().slideToggle(300);
  });
}
