import $ from 'jquery';

const $shapes = $('.shapes');

export default function changeStyle() {
  const delay = Math.random() * (10000 - 5000) + 5000;
  setTimeout(() => {
    $shapes.toggleClass('is-circle');
    changeStyle();
  }, delay);
}
