import $ from 'jquery';

export default function initToTop() {
  const $toTop = $('.to-top');
  const $body = $('html, body');
  $toTop.on('click', (e) => {
    e.preventDefault();
    $body.animate(
      {
        scrollTop: 0,
      },
      600,
    );
  });
}
