import $ from 'jquery';

export default function initSquareButtons() {
  const $button = $('.square-buttons__button');
  $button.on('click', function () {
    const $this = $(this);
    const $content = $this.find('.square-buttons__content');
    const $anotherButton = $this.siblings(); // クリックしていないボタン
    const $anotherContent = $anotherButton.find('.square-buttons__content');
    if ($this.hasClass('is-active')) {
      $this.removeClass('is-active');
      $anotherButton.removeClass('is-small');
      $content.stop().slideUp(300);
    } else {
      $this.removeClass('is-small').addClass('is-active');
      $anotherButton.removeClass('is-active').addClass('is-small');
      $content.stop().slideDown(300);
      $anotherContent.stop().slideUp(300);
    }
  });
}
