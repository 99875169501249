import 'web-animations-js';

let instance;
export default class Marquee {
  constructor(marquee, textWidth) {
    if (instance) return;
    instance = marquee;
    this.width = 0;

    this.option = {
      x: 0,
      duration: 0,
    };

    this.init(textWidth);
  }

  init(textWidth) {
    this.width = Math.max(window.innerWidth, textWidth);
    this.option.x = this.width * 2;
    this.option.duration = this.width * 30;
  }

  setAnimation() {
    this.animation = instance.animate(
      [
        { transform: 'translateX(0)' },
        { transform: `translateX(-${this.option.x}px)` },
      ],
      {
        duration: this.option.duration,
        easing: 'linear',
      },
    );
  }

  start() {
    this.setAnimation();
    setInterval(() => {
      this.animation.finish();
      this.setAnimation();
    }, this.option.duration);
  }

  destory() {
    this.animation.finish();
    instance = '';
    this.width = 0;
    this.option = {
      x: 0,
      duration: 0,
    };
  }
}
