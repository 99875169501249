import $ from 'jquery';
import objectFitImages from 'object-fit-images';
// import Swiper, { Navigation, Pagination, Autoplay, EffectFade } from 'swiper'
// Swiper.use([Navigation, Pagination, Autoplay, EffectFade])

export default function mvSldier() {
  objectFitImages();

  if (window.matchMedia('(max-width: 768px)').matches) {
    const height = $(window).innerHeight();
    $('.mv-slider-02').height(height);
  }

  // eslint-disable-next-line no-unused-vars
  // const swiper = new Swiper('.mv-slider-02', {
  //   effect: 'fade',
  //   loop: true,
  //   autoplay: {
  //     delay: 6000,
  //   },
  //   speed: 600,
  //   pagination: {
  //     el: '.swiper-pagination',
  //     clickable: true,
  //   },
  // })

  const $links = $('a[href^="#"]');
  const $body = $('html, body');
  const $header = $('.header');
  $links.on('click', function (event) {
    event.preventDefault();
    const href = $(this).attr('href');
    const headerTop = $header.innerHeight();
    const scrollTop = $(`${href}`).offset().top - headerTop;
    $body.animate({ scrollTop }, 600);
  });
}
