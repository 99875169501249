import $ from 'jquery';

export default function initCategoryMenu() {
  const $categoryMenuBtn = $('.category-menu__btn');
  $categoryMenuBtn.on('click', function (event) {
    event.preventDefault();
    $(this).toggleClass('is-active');
    $(this).next('.category-menu__list').stop().slideToggle(300);
  });
}
