import $ from 'jquery';

export default function initScrollTable() {
  const $tableScroll = $('.table--scroll');
  $tableScroll.each(function () {
    $(this).on('touchstart', function () {
      $(this).addClass('is-active');
    });
  });
}
