/* eslint-disable */
export default function (isTop) {
  var unit = 100,
    canvas,
    context,
    canvas2,
    context2,
    height,
    width,
    xAxis,
    yAxis,
    draw;

  /**
   * Init function.
   *
   * Initialize variables and begin the animation.
   */
  function init() {
    canvas = document.getElementById('sineCanvas');

    canvas.width = document.documentElement.clientWidth; // Canvasのwidthをウィンドウの幅に合わせる
    canvas.height = 300;

    context = canvas.getContext('2d');

    height = canvas.height;
    width = canvas.width;

    xAxis = Math.floor(height / 2);
    yAxis = 0;

    draw();
  }

  /**
   * Draw animation function.
   *
   * This function draws one frame of the animation, waits 20ms, and then calls
   * itself again.
   */
  function draw() {
    // キャンバスの描画をクリア
    context.clearRect(0, 0, width, height);

    // 波を描画
    // drawWave('#10c2cd', 0.3, 3, 0);
    // drawWave('#43c0e4', 0.4, 2, 250);
    // drawWave('#1d82b6', 0.2, 1.6, 100);
    drawWave('#fff', 0.15, 3, 0);
    drawWave('#fff', isTop ? 0.1 : 1, 2, 250);
    drawWave('#fff', 0.1, 1.6, 100);

    // Update the time and draw again
    draw.seconds += 0.007;
    draw.t = draw.seconds * Math.PI;
    requestAnimationFrame(draw);
  }
  draw.seconds = 0;
  draw.t = 0;

  /**
   * 波を描画
   * drawWave(色, 不透明度, 波の幅のzoom, 波の開始位置の遅れ)
   */
  function drawWave(color, alpha, zoom, delay) {
    context.fillStyle = color;
    context.globalAlpha = alpha;

    context.beginPath(); // パスの開始
    drawSine(draw.t / 0.5, zoom, delay);
    context.lineTo(width + 10, height); // パスをCanvasの右下へ
    context.lineTo(0, height); // パスをCanvasの左下へ
    context.closePath(); // パスを閉じる
    context.fill(); // 塗りつぶす
  }

  /**
   * Function to draw sine
   *
   * The sine curve is drawn in 10px segments starting at the origin.
   * drawSine(時間, 波の幅のzoom, 波の開始位置の遅れ)
   */
  function drawSine(t, zoom, delay) {
    // Set the initial x and y, starting at 0,0 and translating to the origin on
    // the canvas.
    let x = t; // 時間を横の位置とする
    let y = Math.sin(x) / zoom;
    context.moveTo(yAxis, unit * y + xAxis); // スタート位置にパスを置く

    // Loop to draw segments (横幅の分、波を描画)
    for (var i = yAxis; i <= width + 10; i += 10) {
      x = t + (-yAxis + i) / unit / zoom;
      y = Math.sin(x - delay) / 3;
      context.lineTo(i, unit * y + xAxis);
    }
  }
  init();
}
